import Layout from 'components/layout';
import { Field, Formik } from 'formik';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import Checkbox from '../components/checkbox/checkbox';
import BoxSec from '../components/checkbox/checkbox-section';
import Modal from '../components/modal/modal';
import ConditionsContainer from '../components/search/modal-conditions';
import { replaceCompanyName } from '../constants/config';
import { getGeographicalCoordinates } from '../functions/googleMap';
import {
  AdvSearchSection,
  Fonts,
  HeroSectionForPatients,
  SimpleSearch,
} from '../global.css';

const FindATrial = ({ navigate, location, pageContext, data }) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownRadiusOpen, setDropdownRadiusOpen] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [preselectedCountry, setPreselectedCountry] = useState({
    InternalValue: '',
    DisplayValue: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [MileRadius, setMileRadius] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [preselectedZip, setPreselectedZip] = useState('');

  let healthOption = '';

  useEffect(() => {
    setTimeout(() => {
      if (
        typeof window !== 'undefined' &&
        window.manifest &&
        window.manifest.masterData
      ) {
        data.site.siteMetadata.masterData = window.manifest.masterData;
      }
      if (typeof window !== 'undefined') {
        setIsLoading(false);
      }
    }, 100);

    let localSearchParams;
    let localSelectedConditions;

    try {
      localSearchParams = new URL(location.href).searchParams;
    } catch (e) {
      localSearchParams = new URLSearchParams('');
    }
    setSearchParams(localSearchParams);

    if (localSearchParams) {
      if (localSearchParams.get('Conditions')) {
        localSelectedConditions = localSearchParams
          .get('Conditions')
          .split('~');
        setSelectedConditions(localSelectedConditions);
      }
      if (localSearchParams.get('country')) {
        setPreselectedCountry(
          getCountryIfExist(localSearchParams.get('country'))
        );
      }
      if (localSearchParams.get('zip')) {
        setPreselectedZip(localSearchParams.get('zip'));
      }
    }

    if (localSelectedConditions && localSelectedConditions.length) {
      let selectedDisplayValues = [];
      if (Array.isArray(data.site.siteMetadata.masterData.Data.CONDITION)) {
        data.site.siteMetadata.masterData.Data.CONDITION.forEach(
          (condition) => {
            if (localSelectedConditions.includes(condition.InternalValue)) {
              selectedDisplayValues.push(condition.DisplayValue);
            }
            if (condition.Children && condition.Children.length) {
              condition.Children.forEach((condition) => {
                if (localSelectedConditions.includes(condition.InternalValue)) {
                  selectedDisplayValues.push(condition.DisplayValue);
                }
              });
            }
          }
        );
      }
      setSelectedOptions(selectedDisplayValues.join(', '));
      setCountries(data.site.siteMetadata.masterData.Data.COUNTRY);
    } else {
      setCountries(data.site.siteMetadata.masterData.Data.COUNTRY);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSearchCountry('');
  };

  const toggleDropdownRadius = () => {
    setDropdownRadiusOpen(!dropdownRadiusOpen);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const onKeyDownCountry = (event) => {
    if (dropdownOpen && event && event.key && /[a-z,A-Z]/.test(event.key)) {
      let localSearchCountry = searchCountry;
      if (event.key.length === 1) {
        localSearchCountry = searchCountry + event.key;
      } else {
        if (event.which && event.which === 8 && localSearchCountry.length) {
          localSearchCountry = localSearchCountry.substring(
            0,
            localSearchCountry.length - 1
          );
        }
      }
      setSearchCountry(localSearchCountry);
    }
  };

  const resetForm = () => {
    navigate(location.pathname, { replace: true });
    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      window.location.reload();
    }
  };

  const handleSubmit = (values) => {
    navigate(
      `/${pageContext.langKey}/results/?${Object.keys(values)
        .map(
          (k) =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(values[k]) ? values[k].join('~') : `${values[k]}`
            )
        )
        .join('&')}`
    );
  };

  const getCountryIfExist = (country) => {
    let localCountry = data.site.siteMetadata.masterData.Data.COUNTRY.find(
      (item) => item.InternalValue === country
    );
    if (!localCountry) {
      return { InternalValue: '', DisplayValue: '' };
    }
    return {
      InternalValue: localCountry.InternalValue,
      DisplayValue: localCountry.DisplayValue,
    };
  };

  let statuses = data.site.siteMetadata.masterData.Data.RECSTATUS.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let ages = data.site.siteMetadata.masterData.Data.AGERANGE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let genders = data.site.siteMetadata.masterData.Data.GENDER.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let phases = data.site.siteMetadata.masterData.Data.PHASE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let studyTypes = data.site.siteMetadata.masterData.Data.STUDY_TYPE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let availableDocs = data.site.siteMetadata.masterData.Data.ATTACHTYPE
    ? data.site.siteMetadata.masterData.Data.ATTACHTYPE.sort(
        (a, b) => a.SortOrder - b.SortOrder
      )
    : [];
  return isLoading ? (
    ''
  ) : (
    <Layout meta={replaceCompanyName(data.findATrialEnJson, 'seo').seo}>
      <Fonts>
        <HeroSectionForPatients>
          <div className={'content-container'}>
            <h1 className={'searchTitle'}>{data.findATrialEnJson.heroTitle}</h1>
          </div>
        </HeroSectionForPatients>

        <AdvSearchSection>
          {searchParams && (
            <Formik
              initialValues={{
                AgeRanges:
                  (searchParams &&
                    searchParams.get('AgeRanges') &&
                    searchParams.get('AgeRanges').split('~')) ||
                  [],
                Phases:
                  (searchParams &&
                    searchParams.get('Phases') &&
                    searchParams.get('Phases').split('~')) ||
                  [],
                StudyTypes:
                  (searchParams &&
                    searchParams.get('StudyTypes') &&
                    searchParams.get('StudyTypes').split('~')) ||
                  [],
                AttachmentTypes:
                  (searchParams &&
                    searchParams.get('AttachmentTypes') &&
                    searchParams.get('AttachmentTypes').split('~')) ||
                  [],
                SearchTerm:
                  (searchParams && searchParams.get('SearchTerm')) || '',
                Gender: (searchParams && searchParams.get('Gender')) || [],
                Status:
                  (searchParams &&
                    searchParams.get('Status') &&
                    searchParams.get('Status').split('~')) ||
                  [],
                Conditions:
                  selectedConditions && selectedConditions.length
                    ? selectedConditions
                    : [],
                MileRadius:
                  (searchParams && searchParams.get('MileRadius')) || '',
                PatientLevelData:
                  (searchParams && searchParams.get('PatientLevelData')) || [],
                HasResults:
                  (searchParams && searchParams.get('HasResults')) || [],
                country: preselectedCountry.InternalValue,
                zip: preselectedZip,
              }}
              onSubmit={(values, actions) => {
                values.Conditions = selectedConditions;
                values.HealthyVolunteer = healthOption;
                if (
                  values.country &&
                  values.country.length &&
                  values.zip &&
                  values.zip.length
                ) {
                  const countryName = values.country.replace('COUNTRY_', '');
                  getGeographicalCoordinates(
                    countryName,
                    values.zip && values.zip.length ? values.zip : ''
                  )
                    .then((response) => {
                      switch (response.tag) {
                        case 'Success': {
                          const { Longitude, Latitude } = response.value;
                          values.Latitude = Latitude;
                          values.Longitude = Longitude;
                          return true;
                        }
                        case 'Error': {
                          /*eslint quotes: [2, "single", "avoid-escape"]*/
                          alert(
                            typeof response.value === 'string' &&
                              response.value === 'Not found'
                              ? 'Invalid zip code and country combination'
                              : "We can't find specified address"
                          );
                          return false;
                        }
                      }
                    })
                    .then((success) => {
                      actions.setSubmitting(false);
                      if (!success) {
                        return false;
                      }
                      handleSubmit(values);
                    });
                } else {
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }
              }}
              render={({
                handleSubmit,
                handleReset,
                handleChange,
                setFieldValue,
                values,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} onReset={handleReset}>
                  <Row>
                    <Col xs={12} className={'search-label'}>
                      <div
                        style={{ display: 'flex' }}
                        className={'content-container'}
                      >
                        <img src="/images/White.svg" alt="Search" />
                        <label
                          htmlFor={'keyword-section'}
                          className={'accesibility-features'}
                        >
                          Accesibility
                        </label>
                        <Field
                          className={'keySearch'}
                          type="text"
                          maxLength="50"
                          name="SearchTerm"
                          id="keyword-section"
                          autoComplete="off"
                          placeholder={data.findATrialEnJson.keyword}
                        />
                      </div>
                    </Col>
                    <div className={'page-context'}>
                      <div className={'content-container'}>
                        {statuses && statuses.length && (
                          <Col lg={6} xs={12} className={'field-direction'}>
                            <BoxSec
                              title={data.findATrialEnJson.studyStatus}
                              items={statuses}
                              name={'Status'}
                            />
                          </Col>
                        )}
                        <Col lg={6} xs={12}>
                          <SimpleSearch className={'search-line'}>
                            <Row>
                              <Col md={6} xs={12}>
                                <h5>{data.findATrialEnJson.conditions}</h5>
                                <Modal
                                  type={'conditions'}
                                  open={modalOpen}
                                  showModal={showModal}
                                  hideModal={hideModal}
                                  arrow={true}
                                  button={
                                    typeof selectedOptions === 'string'
                                      ? selectedOptions.length
                                        ? selectedOptions
                                        : data.trialSearchEnJson.allSelected
                                      : data.findATrialEnJson.conditions
                                  }
                                  trackEvent={{
                                    label: 'Conditions',
                                  }}
                                >
                                  <ConditionsContainer
                                    conditionsList={
                                      data.site.siteMetadata.masterData.Data
                                        .CONDITION
                                    }
                                    healthVol={
                                      data.site.siteMetadata.masterData.Data
                                        .HEALTHY_VOL
                                    }
                                    handleModal={hideModal}
                                    title={
                                      data.trialSearchEnJson
                                        .selectMedicalCondition
                                    }
                                    all={data.trialSearchEnJson.all}
                                    clear={data.trialSearchEnJson.clear}
                                    accept={data.trialSearchEnJson.accept}
                                    ok={data.trialSearchEnJson.ok}
                                    preselectedConditions={selectedConditions}
                                    onSelected={(list, names, healthyValue) => {
                                      setSelectedConditions(list);
                                      healthOption = healthyValue || '';
                                      setSelectedOptions(names.join(', '));
                                    }}
                                    allSelected={() => {
                                      setSelectedOptions('');
                                      setSelectedConditions([]);
                                    }}
                                  />
                                </Modal>
                              </Col>
                              <Col md={6} xs={12}>
                                <h5>{'Country'}</h5>
                                <Dropdown
                                  isOpen={dropdownOpen}
                                  toggle={toggleDropdown}
                                  className={'select-dropdown'}
                                >
                                  <DropdownToggle
                                    className={'select-button'}
                                    tag="button"
                                    type="button"
                                    name="country"
                                    onKeyDown={(e) => onKeyDownCountry(e)}
                                    onChange={(ev) => {
                                      handleChange(ev);
                                      if (ev.target.value.length === 0)
                                        setFieldValue('zip', '');
                                    }}
                                  >
                                    {searchCountry && searchCountry.length
                                      ? searchCountry
                                      : preselectedCountry.DisplayValue.length
                                      ? preselectedCountry.DisplayValue
                                      : data.findATrialEnJson.country}{' '}
                                    <img
                                      src="/images/Icon.svg"
                                      alt="Dropdown"
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    flip={false}
                                    direction="down"
                                    style={{
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                    }}
                                  >
                                    {[
                                      {
                                        InternalValue: '',
                                        DisplayValue: 'All Countries',
                                      },
                                    ]
                                      .concat(
                                        countries
                                          .filter((country) => {
                                            return searchCountry === ''
                                              ? true
                                              : country.DisplayValue.toLocaleLowerCase().indexOf(
                                                  searchCountry.toLocaleLowerCase()
                                                ) >= 0;
                                          })
                                          .sort(
                                            (a, b) => a.SortOrder - b.SortOrder
                                          )
                                      )
                                      .map((country) => (
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setPreselectedCountry({
                                              InternalValue:
                                                country.InternalValue,
                                              DisplayValue:
                                                country.DisplayValue,
                                            });
                                            handleChange(ev);
                                            setFieldValue(
                                              'country',
                                              country.InternalValue
                                            );
                                            setFieldValue('zip', '');
                                          }}
                                          key={country.InternalValue}
                                          id={country.InternalValue}
                                          value={country.InternalValue}
                                        >
                                          {country.DisplayValue}
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Col md={6} xs={12}>
                                <h5>{data.findATrialEnJson.postalCode}</h5>
                                <label
                                  htmlFor={'postalCode'}
                                  className={'accesibility-features'}
                                >
                                  Accesibility
                                </label>
                                <Field
                                  type="text"
                                  name="zip"
                                  id="postalCode"
                                  maxLength="7"
                                  autoComplete="off"
                                  disabled={
                                    preselectedCountry.InternalValue === ''
                                  }
                                  placeholder={data.findATrialEnJson.postalCode}
                                />
                              </Col>
                              <Col md={6} xs={12}>
                                <h5>{data.findATrialEnJson.radius.title}</h5>
                                <Dropdown
                                  isOpen={dropdownRadiusOpen}
                                  toggle={toggleDropdownRadius}
                                  className={'select-dropdown'}
                                >
                                  <DropdownToggle
                                    className={'select-button'}
                                    tag="button"
                                    type="button"
                                    name="MileRadius"
                                    disabled={
                                      preselectedCountry.InternalValue === '' ||
                                      values.zip === ''
                                    }
                                    onChange={(ev) => {
                                      handleChange(ev);
                                      if (ev.target.value.length === 0)
                                        setFieldValue('');
                                    }}
                                  >
                                    {MileRadius && values.zip != ''
                                      ? MileRadius
                                      : 'Radius'}{' '}
                                    <img
                                      src="/images/Icon.svg"
                                      alt="Dropdown"
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    flip={false}
                                    direction="down"
                                    modifiers={{
                                      setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                          return {
                                            ...data,
                                            styles: {
                                              ...data.styles,
                                              overflow: 'auto',
                                              maxHeight: 300,
                                            },
                                          };
                                        },
                                      },
                                    }}
                                  >
                                    {data.findATrialEnJson.radius.options.map(
                                      (miles) => (
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setMileRadius(`${miles.value}`);
                                            handleChange(ev);
                                            setFieldValue(
                                              'MileRadius',
                                              `${miles.key}`
                                            );
                                            setFieldValue('');
                                          }}
                                          key={miles.value}
                                          value={miles.key}
                                        >
                                          {miles.value}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </SimpleSearch>
                        </Col>
                        {ages && ages.length && (
                          <Col
                            lg={6}
                            xs={12}
                            className={'field-direction' + ' age-col'}
                          >
                            <BoxSec
                              title={data.findATrialEnJson.ageRange}
                              items={ages}
                              name={'AgeRanges'}
                            />
                          </Col>
                        )}
                        <Row className="gender-types-line">
                          {genders && genders.length && (
                            <Col lg={3} xs={12} className={'field-direction'}>
                              <div className={'box-sec'}>
                                <h4>{data.findATrialEnJson.gender}</h4>
                                <div className={'checkboxes-section'}>
                                  {genders.map((item, index) =>
                                    item.InternalValue.indexOf('ALL') >
                                    -1 ? null : (
                                      <Checkbox
                                        key={index}
                                        id={item.InternalValue}
                                        value={item.InternalValue}
                                        displayvalue={item.DisplayValue}
                                        name={'Gender'}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                          )}
                          {studyTypes && studyTypes.length && (
                            <Col lg={6} xs={12} className={'field-direction'}>
                              <div className={'box-sec'}>
                                <h4>{data.findATrialEnJson.studyTypes}</h4>
                                <div className={'checkboxes-section'}>
                                  <Checkbox
                                    id={'STUDY_TYPE_INTERVENTIONAL'}
                                    value={'STUDY_TYPE_INTERVENTIONAL'}
                                    displayvalue={'Interventional'}
                                    name={'StudyTypes'}
                                  />
                                  <Checkbox
                                    id={'STUDY_TYPE_OBSERVATIONAL'}
                                    value={'STUDY_TYPE_OBSERVATIONAL'}
                                    displayvalue={'Non-Interventional'}
                                    name={'StudyTypes'}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                        {phases && phases.length && (
                          <Col lg={6} xs={12} className={'filed-phases'}>
                            <BoxSec
                              title={data.findATrialEnJson.phases}
                              items={phases}
                              name={'Phases'}
                            />
                          </Col>
                        )}
                        {availableDocs && availableDocs.length ? (
                          <Col
                            lg={6}
                            xs={12}
                            id={'documents-section'}
                            className={'field-direction'}
                          >
                            <div className={'box-sec'}>
                              <h4>{data.findATrialEnJson.availableDocs}</h4>
                              <div className={'checkboxes-section'}>
                                {availableDocs.map((item, index) => (
                                  <Checkbox
                                    key={index}
                                    id={item.InternalValue}
                                    value={item.InternalValue}
                                    displayvalue={item.DisplayValue}
                                    name={'AttachmentTypes'}
                                  />
                                ))}
                                <Checkbox
                                  id={'INDIVIDUAL_PATIENT_DATA'}
                                  value={'true'}
                                  displayvalue={'Individual Patient Data'}
                                  name={'PatientLevelData'}
                                />
                                <Checkbox
                                  id={'STUDIES_WITH_RESULTS'}
                                  value={'true'}
                                  displayvalue={'Studies with Results'}
                                  name={'HasResults'}
                                />
                              </div>
                            </div>
                          </Col>
                        ) : null}
                        <Col lg={6} xs={12} className={'actions-section'}>
                          <button
                            disabled={isSubmitting}
                            className={'searchBtn'}
                          >
                            {data.findATrialEnJson.search}
                          </button>
                          <button
                            type="button"
                            onClick={() => resetForm()}
                            className={'resetBtn'}
                          >
                            {data.findATrialEnJson.reset}
                          </button>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </form>
              )}
            />
          )}
        </AdvSearchSection>
      </Fonts>
    </Layout>
  );
};

FindATrial.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  selectedDoc: PropTypes.any,
};

export default FindATrial;

export const query = graphql`
  query FindATrial {
    site {
      siteMetadata {
        masterData {
          Data {
            RECSTATUS {
              InternalValue
              DisplayValue
              SortOrder
            }
            ATTACHTYPE {
              InternalValue
              DisplayValue
              SortOrder
            }
            AGERANGE {
              InternalValue
              DisplayValue
              SortOrder
            }
            GENDER {
              InternalValue
              DisplayValue
              SortOrder
            }
            HEALTHY_VOL {
              InternalValue
              DisplayValue
              SortOrder
            }
            COUNTRY {
              InternalValue
              DisplayValue
              SortOrder
            }
            PHASE {
              InternalValue
              DisplayValue
              SortOrder
            }
            STUDY_TYPE {
              InternalValue
              DisplayValue
              SortOrder
            }
            CONDITION {
              InternalValue
              DisplayValue
              SortOrder
              Children {
                InternalValue
                DisplayValue
                SortOrder
              }
            }
          }
        }
      }
    }
    findATrialEnJson {
      seo {
        pageTitle
        pageDescription
      }
      heroTitle
      studyStatus
      ageRange
      gender
      phases
      studyTypes
      availableDocs
      reset
      search
      conditions
      country
      keyword
      postalCode
      radius {
        title
        options {
          key
          value
        }
      }
    }
    trialSearchEnJson {
      medicalCondition
      selectCountry
      openDropDown
      postalCode
      postalCodeResults
      search
      keyword
      keywordPH
      gender
      age
      trialStatus
      selectMedicalCondition
      conditions
      all
      clear
      accept
      yes
      no
      ok
      buttonText
      allSelected
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
