import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './checkbox';

const BoxSec = ({ title, items, name }) => (
  <div className={'box-sec'}>
    <h4>{title}</h4>
    <div className={'checkboxes-section'}>
      {items.map((item, index) => (
        <Checkbox
          key={index}
          id={item.InternalValue}
          value={item.InternalValue}
          displayvalue={item.DisplayValue}
          name={name}
        />
      ))}
    </div>
  </div>
);

BoxSec.propTypes = {
  title: PropTypes.string,
  items: PropTypes.any,
  name: PropTypes.string,
};

export default BoxSec;
